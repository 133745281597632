// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyCapq8M5KTmGYayG3x7mLHSD2qXfzmyTC4',
    authDomain: 'hour4u-uat.firebaseapp.com',
    databaseURL: 'https://hour4u-uat.firebaseio.com',
    projectId: 'hour4u-uat',
    storageBucket: 'hour4u-uat.appspot.com',
    messagingSenderId: '1057720385634',
    appId: '1:1057720385634:web:76a6341500a6403d26113b',
    measurementId: 'G-ZQJGFM8NR3'

    // NEW firebase account
    // apiKey: "AIzaSyAsqYXZS2AD0sEtISSmW0A-fn-aaeGFPWQ",
    // authDomain: "hour4u-angular.firebaseapp.com",
    // projectId: "hour4u-angular",
    // storageBucket: "hour4u-angular.appspot.com",
    // messagingSenderId: "334397445627",
    // appId: "1:334397445627:web:3bc56bba06618391fc675e",
    // measurementId: "G-5KR06HCKNN"
  },
  log: true,
  environmentName: 'local',
  version: "1.0.23",
  contactSuppportNumber: "918178582667",
  devDomain: 'uatapp.hour4u.com/#/',
  prodDomain: 'app.hour4u.com//#/',
  apiUrls:
  {
    dev: 'https://devapi.hour4u.com/api/', // DEV Api url
    uat: 'https://uatapi.hour4u.com/api/', // UAT Api url
    prod: 'https://api.hour4u.com/api/' // Production Api url
  },
  buildType: 'dev', // If develoment then 'dev' and if prodution the 'prod'
  // buildType: 'prod', // If develoment then 'dev' and if prodution the 'prod'
  apiUrlsList: {
    // dev: [
    //   { authServiceUrl: 'auth-service/v1/' },
    //   { JobService: 'enterprise-job-service/v1/' },
    //   { jobSeekerServiceUrl: 'job-seeker-service/v1/' },
    //   { ratingService: 'rating-service/v1/' },
    //   { paymentService: 'payment-service/api/v1/' },
    //   { referralService: 'referral-service/v1/' }
    // ],
    // DEV AWS API URLs
    dev: [
      { authServiceUrl: 'https://ugmkgcsq10.execute-api.ap-south-1.amazonaws.com/dev/auth-service/v1/' },
      { JobService: 'https://j2v6p0hmy8.execute-api.ap-south-1.amazonaws.com/dev/job-service/v1/' },
      { jobSeekerServiceUrl: 'https://4g2il93m1j.execute-api.ap-south-1.amazonaws.com/dev/job-seeker/v1/' },
      { ratingService: 'https://38vkrahd1e.execute-api.ap-south-1.amazonaws.com/dev/rating-service/v1/' },
      { paymentService: 'https://pnqgfw4i7h.execute-api.ap-south-1.amazonaws.com/dev/payment-service/v1/' },
      { referralService: 'https://9uoguatfp7.execute-api.ap-south-1.amazonaws.com/dev/referral-service/v1/' }
    ],
    // PROD AWS API URLs
    prod: [
      { authServiceUrl: 'https://lexqb3kt8c.execute-api.ap-south-1.amazonaws.com/wo-prod/auth-service/v1/' },
      { JobService: 'https://zycnh7h7zj.execute-api.ap-south-1.amazonaws.com/wo-prod/enterprise-job-service/v1/' },
      { jobSeekerServiceUrl: 'https://xpphw2tkm2.execute-api.ap-south-1.amazonaws.com/wo-prod/job-seeker/v1/' },
      { ratingService: 'https://vvk28pqr98.execute-api.ap-south-1.amazonaws.com/wo-prod/rating-service/v1/' },
      { paymentService: 'https://1rdxocdsqf.execute-api.ap-south-1.amazonaws.com/wo-prod/payment-service/v1/' },
      { referralService: 'https://n2695y2v1h.execute-api.ap-south-1.amazonaws.com/wo-prod/referral-service/v1/' }
    ]
  },
  imageUrl: 'https://image-data-hour4u-2.s3.ap-south-1.amazonaws.com/',
  UxCamAppKey: '5a343j0gz3kv00m',
  bottomTab: [
    {
      label: 'Jobs',
      url: 'jobs',
      name: 'search-outline',
      src: 'assets/inbox-alt.svg',
      class: ''
    },
    {
      label: 'Active Jobs',
      url: 'detactive',
      name: 'bicycle-outline',
      src: 'assets/imagess/groups.png',
      srcActivated: 'assets/images/groups-select.png',
      class: ''
    },
    {
      label: 'Earnings',
      url: 'earnings',
      name: 'wallet-outline',
      src: 'assets/imagess/groups.png',
      srcActivated: 'assets/images/groups-select.png',
      class: ''
    },
    {
      label: 'My Jobs',
      url: 'my-jobs',
      name: 'briefcase-outline',
      src: 'assets/images/home.png',
      srcActivated: 'assets/images/home-select.png',
      class: 'hide',
      disabled: true
    },
    {
      label: 'Profile',
      url: 'profile',
      name: 'person-outline',
      src: 'assets/person.svg',
      srcActivated: 'assets/person.svg',
      class: 'hide',
      disabled: true
    }
  ]
};
