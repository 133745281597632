import { environment } from 'src/environments/environment';

// const RoutePath = environment.apiUrl;
// const ImagePath = environment.imageUrl;
// const UxCamAppKey = environment.UxCamAppKey;
// const authServiceUrl = 'auth-service/v1/';
// const jobSeekerServiceUrl = 'job-seeker-service/v1/';
// const JobService = 'job-service/v1/';
// const ratingService = 'rating-service/v1/';
// const paymentService = 'payment-service/api/v1/';
// const referralService = 'referral-service/v1/';


const buildType = environment.buildType as 'dev' | 'prod';
const RoutePath = environment.apiUrls[buildType];
const ImagePath = environment.imageUrl;
const UxCamAppKey = environment.UxCamAppKey;

const authServiceUrl = environment.apiUrlsList[buildType][0].authServiceUrl;
const JobService = environment.apiUrlsList[buildType][1].JobService;
const jobSeekerServiceUrl = environment.apiUrlsList[buildType][2].jobSeekerServiceUrl;
const ratingService = environment.apiUrlsList[buildType][3].ratingService;
const paymentService = environment.apiUrlsList[buildType][4].paymentService;
const referralService = environment.apiUrlsList[buildType][5].referralService;

export const Apiurl = {
    RoutePath: RoutePath,
    ImagePath: ImagePath,
    UxCamAppKey: UxCamAppKey,
    UpdateToken: jobSeekerServiceUrl + 'jobseeker/updatetoken',
    SendOTPUrl: authServiceUrl + 'auth/mobileNo/otp/',
    LoginWithOTPUrl: authServiceUrl + 'auth/login-otp',
    SavePersonalInfo: jobSeekerServiceUrl + 'jobseeker',
    GetPersonalInfo: jobSeekerServiceUrl + 'jobseeker/mobile/',
    GetPersonalInfo1: jobSeekerServiceUrl + 'jobseeker/',
    UploadProfilePicture: jobSeekerServiceUrl + 'fileupload/jobseeker/profile/',
    UpdateProfilePicture: jobSeekerServiceUrl + 'jobseeker/updateProfilePhoto/',
    GetJobCategory: JobService + 'jobCategory',
    UpdateWorkExperience: jobSeekerServiceUrl + 'jobseeker/updateWorkExperience/',
    SaveWorkExperience: jobSeekerServiceUrl + 'jobseeker/addWorkExperience/',
    EditWorkExperience: jobSeekerServiceUrl + 'jobseeker/editWorkExperience/',
    DeleteWorkExperience: jobSeekerServiceUrl + 'jobseeker/deleteWorkExperience/',
    AddExperience: jobSeekerServiceUrl + 'jobseeker/addExperience/',
    GetJobsList: JobService + 'mobile/jobsearch',
    GetJobByJobId: JobService + 'mobile/jobview/',
    GetJobDetailsGlobally: JobService + 'employment/unsecured/',
    JobPreference: jobSeekerServiceUrl + 'jobpreference',
    GetMyJobs: JobService + 'mobile/myjobs/',
    UpdateHourlyRate: jobSeekerServiceUrl + 'jobpreference/updateRate/',
    RemoveBookMark: JobService + 'jobapplication/removeBookmark/',
    SubmitJobApplication: JobService + 'jobapplication',
    GetActiveJob: JobService + 'mobile/myjobs/activejob/',
    UploadAttendancePicture: JobService + 'fileupload/employmenthistory/',
    SaveAttendanceImgProof: JobService + 'employmentHistory/imageproof',
    UpdateAttendanceImgProof: JobService + 'employmentHistory/updateimageproof',
    DeleteAttendanceImgProof: JobService + 'employmentHistory/deleteimageproof',
    MarkAttendance: JobService + 'employmentAttendance',
    GetEmployeementHistory: JobService + 'employmentHistory',
    getEmployeeAttendance: JobService + 'employmentAttendance',
    SaveRating: ratingService + 'employer',
    GetAllPaymentsRecords: jobSeekerServiceUrl + 'payment/getPaymentByJobSeeker',
    Payment: jobSeekerServiceUrl + 'payment',
    GetEarningStatus: jobSeekerServiceUrl + 'payment/earningStats/',
    SaveAccountDetails: jobSeekerServiceUrl + 'accountdetails',
    GetAccountDetails: jobSeekerServiceUrl + 'accountdetails/getByJobSeekerId/',
    GetWalletBalance: paymentService + 'payment/wallet/',
    GetPayoutHistory: paymentService + 'payment/payout/',
    WithdrawAmount: paymentService + 'payment/payout',
    UpdateAccountDetails: jobSeekerServiceUrl + 'accountdetails/updateById/',
    UploadProfilePictures: jobSeekerServiceUrl + 'fileupload/jobseeker/photo/',
    UpdateProfilePhotos: jobSeekerServiceUrl + 'jobseeker/updatePhotos/',
    UpdateIntroVideo: jobSeekerServiceUrl + 'jobseeker/updateIntroVideo',
    DeleteIntroVideo: jobSeekerServiceUrl + 'jobseeker/removeIntroVideo',
    GetDetailsByJobSeekerId: jobSeekerServiceUrl + 'jobtypepreference/getDetailByJobSeekerId/',
    CheckReferralCode: referralService + 'referral/',
    ChangeApplicationStatus: JobService + 'job/assignment/fulfilment'
};
