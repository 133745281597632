import { Component, NgZone, Optional } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { StatusBar, Style } from '@capacitor/status-bar';
import { ActionSheetController, AlertController, ModalController, NavController, NavParams, Platform } from '@ionic/angular';
import { NetworkService } from './core/services/network.service';
import { CommonProvider } from './core/common';
import { Apiurl } from './core/route';
import { AvailableJobsService } from './available-jobs/available-jobs.service';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Storage } from '@ionic/storage';
import { Capacitor } from '@capacitor/core';
import { AppUpdateService } from './core/services/app-update.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { ActionPerformed, PushNotifications, PushNotificationSchema } from '@capacitor/push-notifications';
import { LoadingService } from './core/services/loading.service';
import { LocalNotifications } from '@capacitor/local-notifications';
import { environment } from 'src/environments/environment';
import { ToastService } from './core/services/toast.service';
import { filter } from 'rxjs/operators';
import { OnboardingJobTypeService } from './onboarding/onboarding-job-type/onboarding-job-type.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { RedirectionService } from './core/services/redirection.service';

declare var UXCam: any;
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

export class AppComponent {

  loaderInterval: any = null;

  constructor(
    public platform: Platform,
    public networkService: NetworkService,
    public router: Router,
    public commonProvider: CommonProvider,
    public availableJobsService: AvailableJobsService,
    public navParams: NavParams,
    // public locationService: LocationService,
    private alertCtrl: AlertController,
    public storage: Storage,
    private zone: NgZone,
    // public pushNotificationService: PushNotificationService,
    public appUpdateService: AppUpdateService,
    public modalCtrl: ModalController,
    public navCtrl: NavController,
    public loadingService: LoadingService,
    private toastService: ToastService,
    public actionSheetController: ActionSheetController,
    public onboardingJobTypeService: OnboardingJobTypeService,
    private deviceService: DeviceDetectorService,
    private redirectionService: RedirectionService

  ) {

    let deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    console.log("deviceInfo--", deviceInfo);
    console.log("isMobile-----", isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    console.log("isTablet----", isTablet);  // returns if the device us a tablet (iPad etc)
    console.log("isDesktopDevice----", isDesktopDevice); // returns if the app is running on a Desktop browser.

    SplashScreen.show({
      autoHide: true,
    });

    router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (event.url.includes('launch-screen1')) {
          this.router.navigateByUrl('launch-screen');
        }
      });

    this.initializeApp();
  }

  ngOnInit() {
  }

  async initializeApp() {

    // check loader available in app every particular time of interval
    this.loaderInterval = setInterval(() => {
      this.loadingService.getTop();
    }, 8000)

    // Deep linking
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(async () => {
        let slug = null;
        if (environment.buildType === 'dev') { // DEV
          slug = this.router.url.split(environment.devDomain).pop();
        } else {
          slug = this.router.url.split(environment.prodDomain).pop();
        }

        if (slug) {
          setTimeout(async () => {
            this.appUpdateService.forDeepLink = true;
            this.appUpdateService.showListPage = false;
            this.appUpdateService.openLinkFromDeeplink = false;

            const loginUserId = await this.storage.get('loginUserId');
            if (slug.includes('available-job-details')) {
              if (loginUserId) {
                slug = slug.replace('available-job-details-global/', 'available-job-details/');
                this.router.navigateByUrl(slug);
              } else {
                this.appUpdateService.openLinkFromDeeplink = true;
                this.appUpdateService.availableJobLink = slug.replace('available-job-details-global/', 'available-job-details/');
                this.router.navigateByUrl(slug);
              }
            }

            if (slug.includes('supervisor-admin') && !loginUserId) {
              this.appUpdateService.showListPage = true;
              this.router.navigateByUrl(slug);
            } else {
              if (slug.includes('supervisor-admin')) {
                if (loginUserId)
                  this.toastService.showMessage("Other Job Seeker already logged in on app!")
              }
            }
          }, 2500)
        }
      });
    });

    this.platform.ready().then(async () => {
      if (Capacitor.getPlatform() !== 'web') {
        // Splash screen duration
        // await SplashScreen.show({
        //   showDuration: 1800,
        //   autoHide: true,
        // });

        // Statusbar background and icons color change
        StatusBar.setBackgroundColor({
          color: '#ffffff'
        });
        StatusBar.setStyle({
          style: Style.Light
        })

        // Block Landscape mode
        if (Capacitor.getPlatform() == 'android') {
          window.screen.orientation.lock('portrait');
        }

        //UX-CAM Setup
        UXCam.optIntoSchematicRecordings();//To enable session video recording on iOS 
        const configuration = {
          userAppKey: Apiurl.UxCamAppKey,
          enableAutomaticScreenNameTagging: true,
          enableImprovedScreenCapture: true,
        }
        UXCam.startWithConfiguration(configuration);

        // App Update Check
        this.appUpdateService.checkAppUpdate();

        // Check current internet connection Status
        this.networkService.getInternetConnectionStatus();

        // Network connection change
        this.networkService.initializeNetworkEvents();

        // Device Back button logic
        this.platform.backButton.subscribeWithPriority(9999, () => {
          document.addEventListener('backbutton', function (event) {
            event.preventDefault();
            event.stopPropagation();
          }, false);
        });

        App.addListener('backButton', ({ canGoBack }) => {

          this.actionSheetController.getTop().then((res) => {
            if (res) {
              this.actionSheetController.dismiss();
              return;
            }
          })

          this.alertCtrl.getTop().then((res) => {
            this.alertCtrl.dismiss();
            return;
          })

          if (canGoBack) {
            this.modalCtrl.getTop().then((res: any) => {
              if (res) {
                this.modalCtrl.dismiss();
              } else {
                if (this.router.url.includes('tabs/available-jobs') || this.router.url.includes('launch-screen') ||
                  this.router.url.includes('onboarding/onboarding-phone-number') || this.router.url.includes('onboarding/onboarding-personal-info')) {
                  let alert = this.alertCtrl.create({
                    header: 'Exit',
                    cssClass: 'exit-alert',
                    id: 'exit-alert',
                    message: 'Are you sure you want to exit app?',
                    buttons: [
                      {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'cancel-btn',
                        handler: () => {
                          console.log('Cancel clicked');
                        }
                      },
                      {
                        text: 'Exit App',
                        cssClass: 'exit-btn',
                        handler: () => {
                          App.exitApp();
                        }
                      }
                    ]
                  });
                  alert.then(res => {
                    res.present();
                  });
                } else {
                  if (!this.onboardingJobTypeService.showExperience) {
                    window.history.back();
                  } else {
                    this.onboardingJobTypeService.showExperience = false;
                  }
                }
              }
            }).catch((err: any) => {
              console.log(err);
            })
          } else {
            let alert = this.alertCtrl.create({
              header: 'Exit',
              message: 'Are you sure you want to exit app?',
              buttons: [
                {
                  text: 'Cancel',
                  role: 'cancel',
                  cssClass: 'cancel-btn',
                  handler: () => {
                    console.log('Cancel clicked');
                  }
                },
                {
                  text: 'Exit App',
                  cssClass: 'exit-btn',
                  handler: () => {
                    App.exitApp();
                  }
                }
              ]
            });
            alert.then(res => {
              res.present();
            });
          }
        });

        // SMS send
        // const writeToClipboard = async () => {
        //   await Clipboard.write({
        //     string: "Hello World!"
        //   });
        // };
        // const checkClipboard = async () => {
        // const { type, value } = await Clipboard.read();

        // console.log(`Got ${type} from clipboard: ${value}`);
        // };

        // PUSH NOTIFICATION START
        await PushNotifications.createChannel({
          id: "testchannel1",
          name: "testchannel1",
          description: "Very urgent message alert",
          sound: "default",
          importance: 4,
          visibility: 1,
          lights: true,
          lightColor: "yellow",
          vibration: true,
        }
        ).then(channel => {
          console.log('channel created', channel);
        }).catch(err => {
          console.log('channel created error', err)
        })

        await PushNotifications.requestPermissions().then(async (result) => {
          if (result.receive === 'granted') {
            // Register with Apple / Google to receive push via APNS/FCM
            await PushNotifications.register();
          } else {
            // Show some error
            console.log(" error ", result)
          }
        });

        // On success, we should be able to receive notifications
        await PushNotifications.addListener('registration', async (token) => {
          console.info('Registration token: ', token.value);
          await this.storage.set('FCMToken', token.value);
          localStorage.setItem('FCMToken', token.value);
          await this.updateToken(token.value)
        });

        // Some issue with our setup and push will not work
        await PushNotifications.addListener('registrationError', (error: any) => {
          console.log('Error on registration: ' + JSON.stringify(error));
        });

        // Show us the notification payload if the app is open on our device
        await PushNotifications.addListener('pushNotificationReceived',
          async (notification: PushNotificationSchema) => {
            console.log('Push received: ', notification);
            PushNotifications.removeAllDeliveredNotifications(); // Remove globally getting notification when app open

            // Send Local push notification from global notification whn app open
            const notifs = await LocalNotifications.schedule({
              notifications: [
                {
                  title: notification?.title,
                  body: notification?.body,
                  id: 1,
                  schedule: { at: new Date(Date.now() + 1000 * 5) },
                  smallIcon: 'icon.png',
                  sound: null,
                  attachments: null,
                  actionTypeId: '',
                  extra: notification?.data,
                },
              ],
            });
            console.log('scheduled notifications', notifs);
          }
        );

        // Method called when tapping on a notification( App in background or killed mode)
        await PushNotifications.addListener('pushNotificationActionPerformed',
          async (notification: ActionPerformed) => {
            console.log('Push action performed---', notification);
            await this.performRedirectionOnNotification(notification?.notification);
          }
        );

        // local push notification when app Open 
        await LocalNotifications.addListener('localNotificationReceived', (data: any) => {
          console.log("localNotificationReceived=====", data)
        })

        // local push notification redirection when app Open 
        await LocalNotifications.addListener('localNotificationActionPerformed', async (data: any) => {
          console.log("localNotificationActionPerformed----", data)
          await this.performRedirectionOnLocalNotification(data);
        })
        // PUSH NOTIFICATION END
      }
    })
  }

  // Update device token for notification
  async updateToken(token) {
    const loginUserMobileNo = await this.storage.get('loginUserMobileNo');
    if (token) {
      if (loginUserMobileNo) {
        let obj = {
          deviceToken: token,
          mobile: loginUserMobileNo
        }
        this.commonProvider.PutMethod(Apiurl.UpdateToken, obj).then(async (res: any) => {
        }).catch(err => {
          console.log(err);
        })
      }
    }
  }

  // Redirect on particular page click on notification start
  // Perform action on notification - When app running in BACKGROUND or KILL MODE
  async performRedirectionOnNotification(notification) {
    console.log("notification---------", notification)
    this.modalCtrl.getTop().then((res: any) => {
      if (res) {
        this.modalCtrl.dismiss();
      }
    })
    setTimeout(async () => {
      const loginUserId = await this.storage.get('loginUserId');
      this.loadingService.dismiss();
      console.log("###############", notification)
      if (notification && notification?.data) {
        if (loginUserId) {
          this.router.navigateByUrl(notification?.data?.redirect_screen);
        } else {
          if (notification?.data?.redirect_screen.includes('available-job-details')) {
            let url = notification?.data?.redirect_screen.replace('available-job-details', 'available-job-details-global');
            this.router.navigateByUrl(url);
          }
        }
        setTimeout(() => {
          this.redirectionService.setRefreshTabOrPage(notification?.data?.redirect_tab);
        }, 0)

        // if (notification?.data?.title == 'Welcome to the club!') {
        //   this.router.navigateByUrl('set-hourly-rates');
        // }
        // if (notification?.data?.title == 'Job Application Actioned') {
        //   this.router.navigateByUrl('tabs/my-jobs');
        // }
        // if (notification?.data?.title == 'Job Reminder') {
        //   this.router.navigateByUrl('tabs/active-job');
        // }
        // if (notification?.data?.title == 'Your payment is approved!' || notification?.data?.title == 'Your money is waiting for you!') {
        //   if (this.router.url.includes('tabs/my-earnings')) {
        //     this.redirectionService.setRefreshMyEarningPage(true);
        //   }
        //   this.router.navigateByUrl('tabs/my-earnings');
        // }
        // if (notification?.data?.title == 'Up to make some bucks?') {
        //   if (loginUserId) {
        //     this.router.navigateByUrl("available-job-details/" + notification?.data?.jobId);
        //   } else {
        //     this.router.navigateByUrl("available-job-details-global/" + notification?.data?.jobId);
        //   }
        // }
      }
    }, 2500);
  }

  // Localnotification action performed - When app is OPEN
  async performRedirectionOnLocalNotification(notification) {
    console.log("local notification---------", notification)
    this.modalCtrl.getTop().then((res: any) => {
      if (res) {
        this.modalCtrl.dismiss();
      }
    })
    setTimeout(async () => {
      const loginUserId = await this.storage.get('loginUserId');
      this.loadingService.dismiss();
      console.log("^^^^^^^^^^^^^^^", notification)
      if (notification && notification?.notification) {
        if (loginUserId) {
          this.router.navigateByUrl(notification?.notification?.extra?.redirect_screen);
        } else {
          if (notification?.notification?.extra?.redirect_screen.includes('available-job-details')) {
            let url = notification?.notification?.extra?.redirect_screen.replace('available-job-details', 'available-job-details-global');
            this.router.navigateByUrl(url);
          }
        }
        setTimeout(() => {
          this.redirectionService.setRefreshTabOrPage(notification?.notification?.extra?.redirect_tab);
        }, 100)

        // if (notification?.notification?.title == 'Welcome to the club!') {
        //   this.router.navigateByUrl('set-hourly-rates');
        // }
        // if (notification?.notification?.title == 'Job Application Actioned') {
        //   this.router.navigateByUrl('tabs/my-jobs');
        // }
        // if (notification?.notification?.title == 'Job Reminder') {
        //   this.router.navigateByUrl('tabs/active-job');
        // }
        // if (notification?.notification?.title == 'Your payment is approved!' || notification?.notification?.title == 'Your money is waiting for you!') {
        //   if (this.router.url.includes('tabs/my-earnings')) {
        //     this.redirectionService.setRefreshMyEarningPage(true);
        //   }
        //   this.router.navigateByUrl('tabs/my-earnings');
        // }
        // if (notification?.notification?.title == 'Up to make some bucks?') {
        //   if (loginUserId) {
        //     this.router.navigateByUrl("available-job-details/" + notification?.notification?.extra?.jobId);
        //   } else {
        //     this.router.navigateByUrl("available-job-details-global/" + notification?.notification?.extra?.jobId);
        //   }
        // }
      }
    }, 1000);
  }

  ngOnDestroy() {
    this.loaderInterval.clear();
  }
}
